<template>
    <v-app class="overflow-hidden">
        <!-- App bar -->
        <app-bar
            v-if="!smAndUp || $route.name !== 'Top'"
            :links="links"
            :subMenu="subMenu"
            :gyms="gyms"
        ></app-bar>

        <!-- Main view -->
        <v-main>
            <router-view :key="$route.fullPath" />
        </v-main>

        <!-- Footer -->
        <revo-footer :footerMenu="footerMenu" :gyms="gyms"></revo-footer>

        <!-- トップに戻るボタン -->
        <return-to-top></return-to-top>
    </v-app>
</template>

<script>
import RevoFooter from '@/components/Footer.vue'
import AppBar from '@/components/AppBar.vue'
import ReturnToTop from '@/components/ReturnToTop.vue'

export default {
    components: {
        RevoFooter,
        AppBar,
        ReturnToTop,
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
    data() {
        return {
            links: [
                {
                    name: 'TOPに戻る',
                    to: { name: 'Top' },
                    brank: false,
                },
                { name: 'レボイストとは', to: { name: 'Revoist' } },
                {
                    name: 'パーソナルトレーニング',
                    to: { name: 'PersonalTraining' },
                },
                { name: '料金・会員種別', to: { name: 'Price' } },
                { name: 'ジム風景', to: { name: 'Scene' } },
                { name: 'トレーナー', to: { name: 'OurTeam' } },
                { name: '店舗一覧', to: { name: 'Gyms' } },
            ],
            subMenu: [
                { name: 'お客さまの声', to: { name: 'Testimonial' } },
                { name: 'よくあるご質問', to: { name: 'Faq' } },
                { name: '会社概要', to: { name: 'Company' } },
                { name: '求人情報', to: { name: 'Recruit' } },
            ],
            gyms: [
                { name: '店舗一覧', to: { name: 'Gyms' } },
                { name: '行徳駅前店', to: { name: 'Access' } },
                { name: '行徳駅前南店', to: { name: 'Access7' } },
                { name: '行徳駅前一丁目店', to: { name: 'Access13' } },
                { name: '南行徳駅前店', to: { name: 'Access3' } },
                { name: '南行徳SOCOLA店', to: { name: 'Access8' } },
                { name: '南行徳新井店', to: { name: 'Access14' } },
                { name: '妙典イオン前店', to: { name: 'Access4' } },
                { name: '妙典末広店', to: { name: 'Access2' } },
                { name: '妙典塩焼店', to: { name: 'Access5' } },
                { name: '妙典三丁目店', to: { name: 'Access11' } },
                { name: '葛西駅前店', to: { name: 'Access12' } },
                { name: '葛西環七通り店', to: { name: 'Access6' } },
                { name: '平井蔵前橋通り店', to: { name: 'Access9' } },
                { name: '浦安駅前店', to: { name: 'Access10' } },
            ],
            footerMenu: [
                { name: '料金・会員種別', to: { name: 'Price' } },
                { name: '会社概要', to: { name: 'Company' } },
                { name: '求人情報', to: { name: 'Recruit' } },
                { name: 'レボイストとは', to: { name: 'Revoist' } },
                {
                    name: 'パーソナルトレーニング',
                    to: { name: 'PersonalTraining' },
                },
                { name: 'ジム風景', to: { name: 'Scene' } },
                { name: 'トレーナー', to: { name: 'OurTeam' } },
                { name: 'お客さまの声', to: { name: 'Testimonial' } },
                { name: 'よくあるご質問', to: { name: 'Faq' } },
            ],
        }
    },
}
</script>

<style lang="scss">
.overflow-hidden {
    overflow: hidden;
}
</style>
