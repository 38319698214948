<template>
    <div v-scroll="onScroll">
        <v-btn
            ref="button"
            class="return-to-top"
            color="#B0BEC5"
            dark
            v-if="shouldShowReturnIcon"
            @click="$vuetify.goTo(0)"
        >
            <v-icon>fas fa-chevron-up</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            shouldShowReturnIcon: false,
        }
    },
    methods: {
        onScroll() {
            this.shouldShowReturnIcon = 800 <= window.scrollY
        },
    },
}
</script>

<style lang="scss" scoped>
.return-to-top {
    position: fixed;
    top: 50%;
    right: -20px;
    z-index: 6;
    box-shadow: 1px 1px 18px #b0bec5;

    .v-icon {
        margin-left: -18px;
        font-size: 1.3rem;
    }
}
</style>
