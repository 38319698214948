<template>
    <v-tabs
        centered
        color="pink lighten-1"
        optional
        background-color="#ffffff"
        height="65px"
        fixed-tabs
    >
        <v-tab
            v-for="link in links"
            :key="link.name"
            class="text-body-1 sub-menu-border"
        >
            <router-link
                class="text-decoration-none black--text"
                :to="link.to"
                :target="link.brank ? '_blank' : ''"
                >{{ link.name }}</router-link
            >
        </v-tab>
    </v-tabs>
</template>

<script>
export default {
    data() {
        return {
            menu: [
                'レボイストとは',
                'パーソナルトレーニング',
                '料金・会員種別',
                'ジム風景',
                'トレーナー',
                '店舗一覧',
            ],
        }
    },
    props: {
        links: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.sub-menu-border {
    border-bottom: 1px solid #e0e0e0;
}
</style>
